<template>
  <v-content class="component white" id="membership">
    <v-layout row wrap>
      <!-- Verbiage -->
      <v-flex xs12 class="header primary--text my-3 text-xs-center">
        <h2>{{ $t("tClickClickDone") }}</h2>
      </v-flex>
      <v-flex xs12 class="max-700 pa-3 black--text">
        <p v-if="$i18n.locale == 'en'"><strong>HASSLE ELIMINATED.</strong> Your vacation — or business trip — has been instantly booked and confirmed, at amazing savings.</p>
        <p v-else>{{ $t("tHassleEliminated") }}</p>
        <p v-if="$i18n.locale == 'en'">As a <strong class="accent--text">G2 GO TRAVEL CLUB</strong> member, you will discover how our <strong>PROPRIETARY TECHNOLOGY</strong> links every property in our system, creating a live comparison search. Our experience and relationships in the industry, coupled with our technological capabilities, bring you a world-class experience every time.</p>
        <p v-else>{{ $t("tHassleEliminated") }}</p>
        <p v-if="$i18n.locale == 'en'">We make it possible for you to book seven days at <strong>MORE THAN 15,000 RESORTS WORLDWIDE AT INCREDIBLY LOW PRICING</strong> and also to take advantage of <strong>DEEP-POCKET DISCOUNTS ON MORE THAN 2.5 MILLION HOTELS UP TO 75% OFF</strong>. <strong class="accent--text">G2 GO TRAVEL CLUB</strong>  consistently delivers more ways to save on hotel rooms, vacation packages and luxury resorts than any of the other leading travel websites, period.</p>
        <p v-else>{{ $t("tMoreWaysToSave") }}</p>
      </v-flex>
      <!-- Cost -->
      <!-- <v-flex xs12 class="text-xs-center">
        <div class="max-700 text-xs-justify pa-3 white black--text" light>
          <h2 class="header text-xs-center">{{ $t("tCost") }}</h2>
          <p>
            {{ $t("tInitialCost") }}
            <strong class="accent--text headline">$299</strong> {{ $t("tAfterInitial") }}
            <strong class="accent--text subheading">$49.95</strong> {{ $t("tPerMonth") }}
          </p>
        </div> -->
           <!-- href="/join.dhtml" -->
          <!-- href="mailto:support@g2gotravelclub.com" -->
          <!-- <v-btn href="/join.dhtml" target="_blank" class="primary black--text mb-3" dark large>{{ $t("tJoin") }}</v-btn> -->
      <!-- </v-flex> -->
    </v-layout>
  </v-content>
</template>

<script>
// import JoinForm from './JoinForm.vue'

export default {
  name: "Membership",
  // compoents: {
  //   JoinForm
  // },
  data: () => ({
    active: null
  })
};
</script>

<style scoped lang="stylus">
@import '~@/main.styl';
</style>