<template>
  <v-content class="component">
    <!-- top parallex -->
    <section class="text-xs-center">
      <v-parallax :src="require('@/assets/stock/beach-foam.jpg')" height="200">
        <span
          class="black--text display-3 white-shadow"
          style="font-weight: 900;"
        >{{ $t("tPrivacyPolicy") }}</span>
      </v-parallax>
    </section>
    <section class="black--text white pa-3">
        <div class="max-700">
          <h4 class="font-weight-black" style="font-size: 1.5em;">{{ $t("tCCSecurity") }}</h4>
          <p>{{ $t("tCCSecurityText") }}</p>
          <h4 class="font-weight-black" style="font-size: 1.5em;">{{ $t("tPrivacy") }}</h4>
          <p>{{ $t("tPrivacyText") }}</p>
          <p>{{ $t("tEmailList") }}</p>
          <h4 class="font-weight-black" style="font-size: 1.5em;">{{ $t("tCookies") }}</h4>
          <p>{{ $t("tCookiesText") }}</p>
          <h4 class="font-weight-black" style="font-size: 1.5em;">{{ $t("tSummary") }}</h4>
          <p>{{ $t("tSummaryText") }}</p>
          <strong class="cursive">{{ $t("tThanks") }}</strong>
        </div>
    </section>
  </v-content>
</template>

<script>
export default {
  name: "Privacy",
  data: () => ({
    referral: null
  })
};
</script>

<style lang="stylus">
@import "~@/main.styl"
</style>
