import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import 'vuetify/src/stylus/app.styl'

// language additions
import ar from 'vuetify/src/locale/ar.ts'
import de from 'vuetify/src/locale/de.ts'
import el from 'vuetify/src/locale/el.ts'
import es from 'vuetify/src/locale/es.ts'
import fr from 'vuetify/src/locale/fr.ts'
import id from 'vuetify/src/locale/id.ts'
import it from 'vuetify/src/locale/it.ts'
import ja from 'vuetify/src/locale/ja.ts'
import nl from 'vuetify/src/locale/nl.ts'
import pt from 'vuetify/src/locale/pt.ts'
import ru from 'vuetify/src/locale/ru.ts'
import th from 'vuetify/src/locale/th.ts'
import zhHans from 'vuetify/src/locale/zh-Hans.ts'
import zhHant from 'vuetify/src/locale/zh-Hant.ts'

Vue.use(Vuetify, {
  iconfont: 'md',
  theme: {
    primary: '#a5cc00',
    secondary: '#161616',
    accent: '#ff9900',
    error: '#ba0041'
  },
  lang: {
    locales: {ar,de,el,es,fr,id,it,ja,nl,pt,ru,th,zhHans,zhHant},
    current: 'en'
  }
})