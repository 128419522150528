<template>
  <v-layout row wrap class="component white black-box-shadow pa-3" light>
    <v-flex xs12>
      <!-- login -->
      <v-form method="post" :action="formAddress" id="loginForm" v-model="loginValid" lazy-validation>
        <v-layout row wrap>
        <h2 class="header black--text text-xs-center pb-3 white-shadow">Choose Desired Login:</h2>
            <v-flex xs12 class="black--text">
              <v-radio-group v-model="loginChoice" row light>
                <v-radio label="Member" value="member"></v-radio>
                <v-radio label="Travel Club" value="travel"></v-radio>
              </v-radio-group>
            </v-flex>
            <v-flex xs12>
              <v-text-field v-if="loginChoice === 'member'" v-model="username" name="username" :rules="usernameRules" label="Username / ID #" required light></v-text-field>
              <v-text-field v-else v-model="email" name="email" :rules="usernameRules" label="E-Mail" required light></v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-text-field v-model="password" name="password" type="password" :rules="passwordRules" label="Password" required light></v-text-field>
            </v-flex>
            <v-flex xs12 class="text-xs-right">
              <v-btn color="primary" :disabled="!loginValid" @click.native="login()">Login</v-btn>
            </v-flex>
            <v-flex xs12 class="caption black--text">If you do not know your username or password, please email support@g2gotravelclub.com</v-flex>
        </v-layout>
      </v-form>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: "Login",
  data: () => ({
    username: null,
    usernameRules: [v => !!v || "Username / ID # / E-Mail Required"],
    password: null,
    passwordRules: [v => !!v || "Password Required"],
    email: null,
    loginValid: false,
    loginChoice: 'member'
  }),
  computed: {
    formAddress () {
      if (this.loginChoice === 'travel') {
        return 'https://membership.g2gotravelclub.com/login/userAuthentication'
      }
      else if (this.loginChoice === 'member') {
        return 'https://shield213.zt1.com/client_homepage.dhtml'
      }
      else {
        return ''
      }
    }
  },
  methods: {
    login() {
      // eslint-disable-next-line
      loginForm.submit()
    }
  }
};
</script>

<style>
.join-form {
  max-width: 80%;
  width: 600px;
  margin: 2em auto;
}
</style>
