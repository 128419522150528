<template>
  <v-content class="white component" id="benefits">
    <v-layout row wrap class="text-xs-center">
      <!-- stats -->
      <v-flex xs4 md2 class="primary--text pt-3">
        <v-icon class="primary--text perk-icon">hotel</v-icon>
        <h4 class="accent--text header-sm font-weight-black">2.5M+</h4>
        <h4 class="subheader black--text">{{ $t("tHotels") }}</h4>
      </v-flex>
      <v-flex xs4 md2 class="primary--text pt-3">
        <v-icon class="primary--text perk-icon">beach_access</v-icon>
        <h4 class="accent--text header-sm font-weight-black">15K+</h4>
        <h4 class="subheader black--text">{{ $t("tResorts") }}</h4>
      </v-flex>
      <v-flex xs4 md2 class="primary--text pt-3">
        <v-icon class="primary--text perk-icon">flight</v-icon>
        <h4 class="accent--text header-sm font-weight-black">950+</h4>
        <h4 class="subheader black--text">{{ $t("tAirlines") }}</h4>
      </v-flex>
      <v-flex xs4 md2 class="primary--text pt-3">
        <v-icon class="primary--text perk-icon">directions_boat</v-icon>
        <h4 class="accent--text header-sm font-weight-black">50+</h4>
        <h4 class="subheader black--text">{{ $t("tCruises") }}</h4>
      </v-flex>
      <v-flex xs4 md2 class="primary--text pt-3">
        <v-icon class="primary--text perk-icon">rowing</v-icon>
        <h4 class="accent--text header-sm font-weight-black">40K+</h4>
        <h4 class="subheader black--text">{{ $t("tActivities") }}</h4>
      </v-flex>
      <v-flex xs4 md2 class="primary--text pt-3">
        <v-icon class="primary--text perk-icon">language</v-icon>
        <h4 class="accent--text header-sm font-weight-black">150+</h4>
        <h4 class="subheader black--text">{{ $t("tCountries") }}</h4>
      </v-flex>
      <!-- Benefits -->
      <v-flex xs12 class="header black--text my-3">
        <h2>{{ $t("tBenefits") }}</h2>
      </v-flex>
      <!-- Best Price -->
      <v-flex xs12 sm6 md4 lg3 class="pa-0">
        <v-hover>
          <v-card
            light
            class="full-width box-shadow"
            slot-scope="{ hover }"
            :class="`elevation-${hover ? 12 : 2}`"
            :click="'showMore(' + perk + ')'"
          >
            <v-img
             :src="require('@/assets/perks/best_price.jpg')"
              class="perk-image"
              height="240px"
            >
              <v-expand-transition>
                <div
                  v-if="hover"
                  class="d-flex transition-fast-in-fast-out white v-card--reveal black--text"
                  style="height: 100%;"
                >
                  <span style="opacity: 1.0 !important;">
                    <v-icon class="perk-icon primary--text">attach_money</v-icon>
                    <h3>{{ $t("tMoneyBack") }}</h3>
                    <hr />
                    <div class="text-xs-left">
                      <p class="px-3">{{ $t("tLowestPrice") }}</p>
                    </div>
                  </span>
                </div>
              </v-expand-transition>
              <div class="perk-title text--uppercase white--text black-shadow-hard">
                <h3 style="opacity: 1.0 !important;">{{ $t("tBestPrice") }}</h3>
              </div>
              <br /><br /><v-icon class="accent--text perk-icon black-shadow-hard">info</v-icon>
            </v-img>
          </v-card>
        </v-hover>
      </v-flex>
      <!-- Hotels -->
      <v-flex xs12 sm6 md4 lg3 class="pa-0">
        <v-hover>
          <v-card
            light
            class="full-width box-shadow"
            slot-scope="{ hover }"
            :class="`elevation-${hover ? 12 : 2}`"
            :click="'showMore(' + perk + ')'"
          >
            <v-img
             :src="require('@/assets/perks/hotel_offers.jpg')"
              class="perk-image"
              height="240px"
            >
              <v-expand-transition>
                <div
                  v-if="hover"
                  class="d-flex transition-fast-in-fast-out white v-card--reveal black--text"
                  style="height: 100%;"
                >
                  <span style="opacity: 1.0 !important;">
                    <v-icon class="perk-icon primary--text">hotel</v-icon>
                    <h3>{{ $t("tHotelRates") }}</h3>
                    <hr />
                    <div class="text-xs-left">
                      <ul>
                        <li>{{ $t("t75OffHotels") }}</li>
                        <li>{{ $t("tVacancy") }}</li>
                        <li>{{ $t("t110BestPrice") }}</li>
                      </ul>
                    </div>
                  </span>
                </div>
              </v-expand-transition>
              <div class="perk-title text--uppercase white--text black-shadow-hard">
                <h3 style="opacity: 1.0 !important;">{{ $t("tHotels") }}</h3>
              </div>
              <br /><br /><v-icon class="accent--text perk-icon black-shadow-hard">info</v-icon>
            </v-img>
          </v-card>
        </v-hover>
      </v-flex>
      <!-- Cruises -->
      <v-flex xs12 sm6 md4 lg3 class="pa-0">
        <v-hover>
          <v-card
            light
            class="full-width box-shadow"
            slot-scope="{ hover }"
            :class="`elevation-${hover ? 12 : 2}`"
            :click="'showMore(' + perk + ')'"
          >
            <v-img
             :src="require('@/assets/perks/cruise_rates.jpg')"
              class="perk-image"
              height="240px"
            >
              <v-expand-transition>
                <div
                  v-if="hover"
                  class="d-flex transition-fast-in-fast-out white v-card--reveal black--text"
                  style="height: 100%;"
                >
                  <span style="opacity: 1.0 !important;">
                    <v-icon class="perk-icon primary--text">directions_boat</v-icon>
                    <h3>{{ $t("tCruiseRates") }}</h3>
                    <hr />
                    <div class="text-xs-left">
                      <ul>
                        <li>{{ $t("t50CruiseLines") }}</li>
                        <li>{{ $t("tNonpublished") }}</li>
                      </ul>
                    </div>
                  </span>
                </div>
              </v-expand-transition>
              <div class="perk-title text--uppercase white--text black-shadow-hard">
                <h3 style="opacity: 1.0 !important;">{{ $t("tCruises") }}</h3>
              </div>
              <br /><br /><v-icon class="accent--text perk-icon black-shadow-hard">info</v-icon>
            </v-img>
          </v-card>
        </v-hover>
      </v-flex>
      <!-- Vacation Rentals -->
      <v-flex xs12 sm6 md4 lg3 class="pa-0">
        <v-hover>
          <v-card
            light
            class="full-width box-shadow"
            slot-scope="{ hover }"
            :class="`elevation-${hover ? 12 : 2}`"
            :click="'showMore(' + perk + ')'"
          >
            <v-img
             :src="require('@/assets/perks/vacation_rentals.jpg')"
              class="perk-image"
              height="240px"
            >
              <v-expand-transition>
                <div
                  v-if="hover"
                  class="d-flex transition-fast-in-fast-out white v-card--reveal black--text"
                  style="height: 100%;"
                >
                  <span style="opacity: 1.0 !important;">
                    <v-icon class="perk-icon primary--text">beach_access</v-icon>
                    <h3>{{ $t("tVacationDeals") }}</h3>
                    <hr />
                    <div class="text-xs-left">
                      <ul>
                        <li>{{ $t("tDestinations") }}</li>
                        <li>{{ $t("tInclusive") }}</li>
                      </ul>
                    </div>
                  </span>
                </div>
              </v-expand-transition>
              <div class="perk-title text--uppercase white--text black-shadow-hard">
                <h3 style="opacity: 1.0 !important;">{{ $t("tVacation") }}</h3>
              </div>
              <br /><br /><v-icon class="accent--text perk-icon black-shadow-hard">info</v-icon>
            </v-img>
          </v-card>
        </v-hover>
      </v-flex>
      <!-- Flights -->
      <v-flex xs12 sm6 md4 lg3 class="pa-0">
        <v-hover>
          <v-card
            light
            class="full-width box-shadow"
            slot-scope="{ hover }"
            :class="`elevation-${hover ? 12 : 2}`"
            :click="'showMore(' + perk + ')'"
          >
            <v-img
             :src="require('@/assets/perks/flights.jpg')"
              class="perk-image"
              height="240px"
            >
              <v-expand-transition>
                <div
                  v-if="hover"
                  class="d-flex transition-fast-in-fast-out white v-card--reveal black--text"
                  style="height: 100%;"
                >
                  <span style="opacity: 1.0 !important;">
                    <v-icon class="perk-icon primary--text">flight_takeoff</v-icon>
                    <h3>{{ $t("tFlightDiscounts") }}</h3>
                    <hr />
                    <div class="text-xs-left">
                      <ul>
                        <li>{{ $t("tLowestTickets") }}</li>
                        <li>{{ $t("tIntlFlights") }}</li>
                        <li>{{ $t("t110BestPrice") }}</li>
                      </ul>
                    </div>
                  </span>
                </div>
              </v-expand-transition>
              <div class="perk-title text--uppercase white--text black-shadow-hard">
                <h3 style="opacity: 1.0 !important;">{{ $t("tFlights") }}</h3>
              </div>
              <br /><br /><v-icon class="accent--text perk-icon black-shadow-hard">info</v-icon>
            </v-img>
          </v-card>
        </v-hover>
      </v-flex>
      <!-- Car Rentals -->
      <v-flex xs12 sm6 md4 lg3 class="pa-0">
        <v-hover>
          <v-card
            light
            class="full-width box-shadow"
            slot-scope="{ hover }"
            :class="`elevation-${hover ? 12 : 2}`"
            :click="'showMore(' + perk + ')'"
          >
            <v-img
             :src="require('@/assets/perks/car_rental.jpg')"
              class="perk-image"
              height="240px"
            >
              <v-expand-transition>
                <div
                  v-if="hover"
                  class="d-flex transition-fast-in-fast-out white v-card--reveal black--text"
                  style="height: 100%;"
                >
                  <span style="opacity: 1.0 !important;">
                    <v-icon class="perk-icon primary--text">directions_car</v-icon>
                    <h3>{{ $t("tCarriers") }}</h3>
                    <hr />
                    <div class="text-xs-left">
                      <ul>
                        <li>{{ $t("tAgencies") }}</li>
                        <li>{{ $t("tUnpublished") }}</li>
                      </ul>
                    </div>
                  </span>
                </div>
              </v-expand-transition>
              <div class="perk-title text--uppercase white--text black-shadow-hard">
                <h3 style="opacity: 1.0 !important;">{{ $t("tCarRentals") }}</h3>
              </div>
              <br /><br /><v-icon class="accent--text perk-icon black-shadow-hard">info</v-icon>
            </v-img>
          </v-card>
        </v-hover>
      </v-flex>
      <!-- Experiences -->
      <v-flex xs12 sm6 md4 lg3 class="pa-0">
        <v-hover>
          <v-card
            light
            class="full-width box-shadow"
            slot-scope="{ hover }"
            :class="`elevation-${hover ? 12 : 2}`"
            :click="'showMore(' + perk + ')'"
          >
            <v-img
             :src="require('@/assets/perks/engage_experiences.jpg')"
              class="perk-image"
              height="240px"
            >
              <v-expand-transition>
                <div
                  v-if="hover"
                  class="d-flex transition-fast-in-fast-out white v-card--reveal black--text"
                  style="height: 100%;"
                >
                  <span style="opacity: 1.0 !important;">
                    <v-icon class="perk-icon primary--text">whatshot</v-icon>
                    <h3>{{ $t("tWonderBreak") }}</h3>
                    <hr />
                    <div class="text-xs-left">
                      <ul>
                        <li>{{ $t("tEvents") }}</li>
                        <li>{{ $t("tNoWastedTime") }}</li>
                      </ul>
                    </div>
                  </span>
                </div>
              </v-expand-transition>
              <div class="perk-title text--uppercase white--text black-shadow-hard">
                <h3 style="opacity: 1.0 !important;">{{ $t("tExperiences") }}</h3>
              </div>
              <br /><br /><v-icon class="accent--text perk-icon black-shadow-hard">info</v-icon>
            </v-img>
          </v-card>
        </v-hover>
      </v-flex>
      <!-- Airport Lounges -->
      <v-flex xs12 sm6 md4 lg3 class="pa-0">
        <v-hover>
          <v-card
            light
            class="full-width box-shadow"
            slot-scope="{ hover }"
            :class="`elevation-${hover ? 12 : 2}`"
            :click="'showMore(' + perk + ')'"
          >
            <v-img
             :src="require('@/assets/perks/airport_lounge.jpg')"
              class="perk-image"
              height="240px"
            >
              <v-expand-transition>
                <div
                  v-if="hover"
                  class="d-flex transition-fast-in-fast-out white v-card--reveal black--text"
                  style="height: 100%;"
                >
                  <span style="opacity: 1.0 !important;">
                    <v-icon class="perk-icon primary--text">weekend</v-icon>
                    <h3>{{ $t("tLoungeAccess") }}</h3>
                    <hr />
                    <div class="text-xs-left">
                      <ul>
                        <li>{{ $t("tGlobalNetwork") }}</li>
                        <li>{{ $t("tDowntime") }}</li>
                        <li>{{ $t("tGoldMembers") }}</li>
                      </ul>
                    </div>
                  </span>
                </div>
              </v-expand-transition>
              <div class="perk-title text--uppercase white--text black-shadow-hard">
                <h3 style="opacity: 1.0 !important;">{{ $t("tAirportLounges") }}</h3>
              </div>
              <br /><br /><v-icon class="accent--text perk-icon black-shadow-hard">info</v-icon>
            </v-img>
          </v-card>
        </v-hover>
      </v-flex>
      <!-- Insurance -->
      <v-flex xs12 sm6 md4 lg3 class="pa-0">
        <v-hover>
          <v-card
            light
            class="full-width box-shadow"
            slot-scope="{ hover }"
            :class="`elevation-${hover ? 12 : 2}`"
            :click="'showMore(' + perk + ')'"
          >
            <v-img
             :src="require('@/assets/perks/flight_insurance.jpg')"
              class="perk-image"
              height="240px"
            >
              <v-expand-transition>
                <div
                  v-if="hover"
                  class="d-flex transition-fast-in-fast-out white v-card--reveal black--text"
                  style="height: 100%;"
                >
                  <span style="opacity: 1.0 !important;">
                    <v-icon class="perk-icon primary--text">flight_land</v-icon>
                    <h3>{{ $t("tAlwaysFree") }}</h3>
                    <hr />
                    <div class="text-xs-left">
                      <ul>
                        <li>{{ $t("tAmount") }}</li>
                        <li>{{ $t("tTypical") }}</li>
                        <li>{{ $t("tGoldMembers") }}</li>
                      </ul>
                    </div>
                  </span>
                </div>
              </v-expand-transition>
              <div class="perk-title text--uppercase white--text black-shadow-hard">
                <h3 style="opacity: 1.0 !important;">{{ $t("tInsurance") }}</h3>
              </div>
              <br /><br /><v-icon class="accent--text perk-icon black-shadow-hard">info</v-icon>
            </v-img>
          </v-card>
        </v-hover>
      </v-flex>
      <!-- Passport -->
      <v-flex xs12 sm6 md4 lg3 class="pa-0">
        <v-hover>
          <v-card
            light
            class="full-width box-shadow"
            slot-scope="{ hover }"
            :class="`elevation-${hover ? 12 : 2}`"
            :click="'showMore(' + perk + ')'"
          >
            <v-img
             :src="require('@/assets/perks/passport.jpg')"
              class="perk-image"
              height="240px"
            >
              <v-expand-transition>
                <div
                  v-if="hover"
                  class="d-flex transition-fast-in-fast-out white v-card--reveal black--text"
                  style="height: 100%;"
                >
                  <span style="opacity: 1.0 !important;">
                    <v-icon class="perk-icon primary--text">offline_bolt</v-icon>
                    <h3>{{ $t("tFastPass") }}</h3>
                    <hr />
                    <div class="text-xs-left">
                      <ul>
                        <li>{{ $t("tServiceFees") }}</li>
                        <li>{{ $t("tTools") }}</li>
                        <li>{{ $t("tGoldMembers") }}</li>
                      </ul>
                    </div>
                  </span>
                </div>
              </v-expand-transition>
              <div class="perk-title text--uppercase white--text black-shadow-hard">
                <h3 style="opacity: 1.0 !important;">{{ $t("tVisa") }}</h3>
              </div>
              <br /><br /><v-icon class="accent--text perk-icon black-shadow-hard">info</v-icon>
            </v-img>
          </v-card>
        </v-hover>
      </v-flex>
      <!-- Support -->
      <v-flex xs12 sm6 md4 lg3 class="pa-0">
        <v-hover>
          <v-card
            light
            class="full-width box-shadow"
            slot-scope="{ hover }"
            :class="`elevation-${hover ? 12 : 2}`"
            :click="'showMore(' + perk + ')'"
          >
            <v-img
             :src="require('@/assets/perks/support.jpg')"
              class="perk-image"
              height="240px"
            >
              <v-expand-transition>
                <div
                  v-if="hover"
                  class="d-flex transition-fast-in-fast-out white v-card--reveal black--text"
                  style="height: 100%;"
                >
                  <span style="opacity: 1.0 !important;">
                    <v-icon class="perk-icon primary--text">perm_phone_msg</v-icon>
                    <h3>{{ $t("tMultilingualSupport") }}</h3>
                    <hr />
                    <div class="text-xs-left">
                      <ul>
                        <li>{{ $t("tMultilingualBookings") }}</li>
                        <li>{{ $t("tPreferredChannel") }}</li>
                      </ul>
                    </div>
                  </span>
                </div>
              </v-expand-transition>
              <div class="perk-title text--uppercase white--text black-shadow-hard">
                <h3 style="opacity: 1.0 !important;">{{ $t("t247Support") }}</h3>
              </div>
              <br /><br /><v-icon class="accent--text perk-icon black-shadow-hard">info</v-icon>
            </v-img>
          </v-card>
        </v-hover>
      </v-flex>
      <!-- Best Price -->
      <v-flex xs12 sm6 md4 lg3 class="pa-0">
        <v-hover>
          <v-card
            light
            class="full-width box-shadow"
            slot-scope="{ hover }"
            :class="`elevation-${hover ? 12 : 2}`"
            :click="'showMore(' + perk + ')'"
          >
            <v-img
             :src="require('@/assets/perks/best_price.jpg')"
              class="perk-image"
              height="240px"
            >
              <v-expand-transition>
                <div
                  v-if="hover"
                  class="d-flex transition-fast-in-fast-out white v-card--reveal black--text"
                  style="height: 100%;"
                >
                  <span style="opacity: 1.0 !important;">
                    <v-icon class="perk-icon primary--text">attach_money</v-icon>
                    <h3>{{ $t("tMoneyBack") }}</h3>
                    <hr />
                    <div class="text-xs-left">
                      <p class="px-3">{{ $t("tLowestPrice") }}</p>
                    </div>
                  </span>
                </div>
              </v-expand-transition>
              <div class="perk-title text--uppercase white--text black-shadow-hard">
                <h3 style="opacity: 1.0 !important;">{{ $t("tBestPrice") }}</h3>
              </div>
              <br /><br /><v-icon class="accent--text perk-icon black-shadow-hard">info</v-icon>
            </v-img>
          </v-card>
        </v-hover>
      </v-flex>
    </v-layout>
  </v-content>
</template>

<script>
export default {
  name: "Perks",
  data: () => ({
    active: null
  })
};
</script>

<style scoped lang="stylus">
@import '~@/main.styl';

.full-height .flex {
  display: flex;
}

.full-height .flex > .card {
  flex: 1 1 auto;
}
</style>