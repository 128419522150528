<template>
  <v-content class="component">
    <!-- top parallex -->
    <section class="text-xs-center">
      <v-parallax :src="require('@/assets/stock/work-map.jpg')" class="full-height">
        <span
          class="black--text display-3 white-shadow"
          style="font-weight: 900;"
        >{{ $t("LogIn") }}</span>
        <div class="max-700">
          <Login></Login>
        </div>
      </v-parallax>
    </section>
  </v-content>
</template>

<script>
import Login from '../components/Login.vue'
export default {
  name: "LoginPage",
  components: {
    Login
  },
  data: () => ({
    referral: null
  })
};
</script>

<style lang="stylus">
@import "~@/main.styl"
</style>
