<template>
  <v-content class="component white black--text" id="opportunity">
    <v-layout row wrap>
      <!-- Opportunity -->
      <v-flex xs12 class="header mt-3 text-xs-center">
        <h2 class="primary--text">{{ $t("tTravelFree") }}</h2>
      </v-flex>
      <!-- <v-flex xs12 class="subheading mb-3 text-xs-center">
        <h3>ENJOY FINANCIAL INDEPENDENCE</h3>
      </v-flex> -->
      <v-flex xs12 class="max-700 pa-3 black--text">
        <p>{{ $t("tSpendTime") }}</p>
        <h4 class="subheading font-weight-bold">{{ $t("tExclusiveSystem") }}</h4>
        <ul>
          <li>{{ $t("tAutoMarketing") }}</li>
          <li>{{ $t("tMemberBackOffice") }}</li>
          <li>{{ $t("tMarketingTools") }}</li>
          <li>{{ $t("tTrainingMaterials") }}</li>
        </ul>
        <br>
        <p v-if="$i18n.locale == 'en'">What does this mean for you? You can <strong class="accent--text">EARN MONEY</strong> while travelling the world at <strong class="accent--text">DEEPLY DISCOUNTED RATES!</strong></p>
        <p v-else>{{ $t("tEarnMoney") }}</p>
        <p>
          <!-- <v-dialog v-model="compensationOverlay" width="80vw" >
            <template v-slot:activator="{ on }">
              <strong v-on="on" class="primary--text pointer-cursor">{{ $t("tClickHere") }}</strong>
            </template>
            <Compensation></Compensation>
          </v-dialog>{{ $t("tViewDetails") }}</p> -->
        <div class="text-xs-center">
          <!-- href="/join.dhtml" -->
          <!-- href="mailto:support@g2gotravelclub.com" -->
          <!-- <v-btn href="/join.dhtml" target="_blank" class="primary black--text mb-3" dark large>{{ $t("tJoin") }}</v-btn> -->
        </div>
      </v-flex>
    </v-layout>
  </v-content>
</template>

<script>
// import Compensation from './Compensation.vue'

export default {
  name: "Opportunity",
  // components: {
  //   Compensation
  // },
  data: () => ({
    compensationOverlay: false
  })
};
</script>

<style scoped lang="stylus">
@import '~@/main.styl';
</style>