<template>
  <v-app dark>
    <!-- top toolbar -->
    <v-toolbar dark fixed style="min-height: 56px;">
      <v-toolbar-title>
        <a href="#top" v-smooth-scroll>
          <img
            src="./assets/g2_nav_56.png"
            class="nav-logo--hidden"
            id="nav-logo"
            alt="G2 Go Travel Club"
          />
        </a>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <!-- language selector -->
        <v-menu bottom offset-y>
          <template v-slot:activator="{ on }">
            <v-btn flat class="menu-buttons display-1" v-on="on">
              <span class="locale">{{ $t("tLanguage") }}</span>
              <!-- <span class="locale">{{ $i18n.locale }}</span> -->
              <i class="fa fa-flag primary--text"></i>
            </v-btn>
          </template>
          <v-list>
            <v-subheader>Choose Language</v-subheader>
            <v-list-tile v-for="language in languageOptions" :key="language.abbr" @click="changeLocale(language.abbr)">
              <!-- <v-list-tile-action>
                <v-icon>close</v-icon>
              </v-list-tile-action> -->
              <v-list-tile-content>
                <v-list-tile-title>{{ language.name }}</v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
          </v-list>
        </v-menu>
        <!-- social media links (tablet and desktop) -->
        <v-btn flat class="menu-buttons display-1 hidden-xs-only">
          <a href="https://www.instagram.com/g2gotravelclub/" target="_blank">
            <i class="fa fa-instagram"></i>
          </a>
        </v-btn>
        <v-btn flat class="menu-buttons display-1 hidden-xs-only">
          <a href="https://www.facebook.com/G2gotravelinfo/" target="_blank">
            <i class="fa fa-facebook-official" aria-hidden="true"></i>
          </a>
        </v-btn>

        <v-btn flat @click="navDrawer=!navDrawer" class="menu-buttons primary--text display-1">
          <i class="fa fa-bars"></i>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <!-- navigation drawer -->
    <v-navigation-drawer
      dark
      fixed
      right
      v-model="navDrawer"
      id="nav-drawer"
      disable-resize-watcher
    >
      <v-list class="transparent-bg">
        <v-list-tile @click="navDrawer = false">
          <v-list-tile-action>
            <v-icon>close</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>{{ $t("tClose") }}</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
      <v-divider></v-divider>
      <!-- list if route "/" just scrolls -->
      <v-list v-if="this.$route.path == '/'" class="transparent-bg">
        <a href="#benefits" v-smooth-scroll="{offset:-56}">
          <v-list-tile @click="navDrawer = false">
            <v-list-tile-action>
              <v-icon>card_membership</v-icon>
            </v-list-tile-action>
            <v-list-tile-content class="white--text">
              <v-list-tile-title>{{ $t("tBenefits") }}</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </a>
        <a href="#opportunity" v-smooth-scroll="{offset:-56}">
          <v-list-tile @click="navDrawer = false">
            <v-list-tile-action>
              <v-icon>attach_money</v-icon>
            </v-list-tile-action>
            <v-list-tile-content class="white--text">
              <v-list-tile-title>{{ $t("tTravelFree") }}</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </a>
        <a href="#about" v-smooth-scroll="{offset:-56}">
          <v-list-tile @click="navDrawer = false">
            <v-list-tile-action>
              <v-icon>info</v-icon>
            </v-list-tile-action>
            <v-list-tile-content class="white--text">
              <v-list-tile-title>{{ $t("tWho") }}</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </a>
      </v-list>
      <!-- if other route goes to home -->
      <v-list v-else>
        <v-list-tile to="/" v-smooth-scroll="{offset:-56}" @click="navDrawer = false">
          <v-list-tile-action>
            <v-icon>home</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>{{ $t("tHome") }}</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
      <!-- join & login buttons -->
      <!-- href="/join.dhtml" -->
      <!-- href="mailto:support@g2gotravelclub.com" -->
      <!-- <div class="mt-5 pa-3">
        <v-btn
          block
          large
          class="primary black--text header pa-3"
          href="/join.dhtml"
          target="_blank"
        > -->
          <!-- <v-icon class="pr-3">loyalty</v-icon>{{ $t("tJoin") }} -->
        <!-- </v-btn> -->
      <!-- </div> -->
      <div class="px-3">
        <v-btn block large class="primary black--text header pa-3" href="#" @click="loginShow()">
          <v-icon class="pr-3">vpn_key</v-icon>{{ $t("tLogIn") }}
        </v-btn>
        <v-dialog v-model="loginOverlay" width="300">
          <div class="max-700">
            <LoginForm></LoginForm>
          </div>
        </v-dialog>
      </div>
    </v-navigation-drawer>
    <!-- router injection -->
    <router-view></router-view>
    <!-- footer -->
    <v-footer color="black" id="app-footer" height="auto">
      <v-container fill-height grid-list-md text-xs-center class="max-700">
        <v-layout row wrap align-center class="black">
          <v-flex xs12 sm6 class="mb-3 text-xs-center">
            <!-- social media links (mobile) -->
            <v-layout row align-center class="black">
              <v-flex xs6>
                <v-btn
                  href="https://www.instagram.com/g2gotravelclub/"
                  target="_blank"
                  class="full-width header primary black--text"
                >
                  <i class="fa fa-instagram"></i>
                </v-btn>
              </v-flex>
              <v-flex xs6>
                <v-btn
                  href="https://www.facebook.com/G2gotravelinfoclub/"
                  target="_blank"
                  class="full-width header primary black--text"
                >
                  <i class="header fa fa-facebook-official"></i>
                </v-btn>
              </v-flex>
            </v-layout>
            <v-btn href="mailto:support@g2gotravelclub.com" class="primary black--text full-width">
              <v-icon class="pr-2">email</v-icon>{{ $t("tEmailUs") }}
            </v-btn>
            <!-- <v-btn href="tel:+13072018088" class="primary black--text full-width">
              <v-icon class="pr-2">phone</v-icon>+1 (307) 201-8088
            </v-btn> -->
          </v-flex>
          <v-flex xs12 sm6 class="mb-3">
            <p>
              <em class="cursive">{{ $t("tTagline") }}</em>
            </p>
            &copy; {{ new Date().getFullYear() }}
            <br />
            <strong>G2 Go Travel Inc.</strong>
          </v-flex>
          <v-flex xs12 sm3>
            <router-link to="/privacy">{{ $t("tPrivacyPolicy") }}</router-link>
          </v-flex>
          <v-flex xs12 sm6>
            <a
              href="/pdf/G2_Go_Travel_Inc_Policies_Procedures_Representative_Agreement.pdf"
            >{{ $t("tRepAgreement") }}</a>
          </v-flex>
          <v-flex xs12 sm3>
            <router-link to="/terms">{{ $t("tTerms") }}</router-link>
          </v-flex>
          <v-flex xs12 class="text-xs-center pa-1">
          </v-flex>
        </v-layout>
      </v-container>
    </v-footer>
  </v-app>
</template>

<script>
import LoginForm from "./components/Login.vue";

export default {
  name: "App",
  components: {
    LoginForm
  },
  data() {
    return {
      companyName: "G2 Go Travel Club",
      companyLogo: "./assets/g2_logo.jpg",
      navDrawer: false,
      loginOverlay: false,
      joinOverlay: false,
      languageOptions: [
        { abbr: "en", name: "English" },
        { abbr: "zh", name: "中文" },
        {abbr: "es", name: "Español" },
        { abbr: "vi", name: "Tiếng Việt" }
      ],
      language: "en",
      currencyList: ["US Dollars"]
    };
  },
  methods: {
    handleScroll() {
      let header = document.querySelector(".v-toolbar");
      let logo = document.querySelector("#nav-logo");
      // toolbar background
      if (
        window.scrollY > 100 &&
        !header.className.includes("v-toolbar--scroll")
      ) {
        header.classList.add("v-toolbar--scroll");
        header.classList.add("black--text");
      } else if (window.scrollY < 100) {
        header.classList.remove("v-toolbar--scroll");
      }
      // nav logo
      if (window.scrollY > 100 && logo.className.includes("nav-logo--hidden")) {
        logo.classList.remove("nav-logo--hidden");
      } else if (window.scrollY < 100) {
        logo.classList.add("nav-logo--hidden");
      }
      // // menu buttons
      var buttons = document.querySelectorAll(".menu-buttons");
      buttons.forEach(function(button) {
        if (window.scrollY > 100 && button.className.includes("white--text")) {
          button.classList.add("black--text");
          button.classList.remove("white--text");
        } else if (window.scrollY < 100) {
          button.classList.add("white--text");
          button.classList.remove("black--text");
        }
      });
    },
    changeLocale(locale) {
      this.$vuetify.lang.current = locale;
      this.$i18n.locale = locale;
    },
    loginShow() {
      this.loginOverlay = true;
    },
    joinShow() {
      this.joinOverlay = true;
    }
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  mounted() {
    this.handleScroll();
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  }
};
</script>

<style lang="stylus">
@import 'main.styl';
</style>
