<template>
  <v-content class="component">
    <!-- top parallex -->
    <section class="text-xs-center">
      <v-parallax :src="require('@/assets/stock/beach-foam.jpg')" height="200">
        <span
          class="black--text display-3 white-shadow"
          style="font-weight: 900;"
        >{{ $t("tTerms") }}</span>
      </v-parallax>
    </section>
    <section class="black--text white pa-3">
        <div class="max-700">
            <p>{{ $t("tTermsText") }}</p>
            <h4 class="font-weight-black" style="font-size: 1.5em;">{{ $t("tVenue") }}</h4>
            <p>{{ $t("tVenueText") }}/p>
            <p>{{ $t("tVenueText2") }}</p>
            <p>{{ $t("tVenueText3") }}</p>
            <p>{{ $t("tVenueText4") }}</p>
            <strong>{{ $t("tAcceptTerms") }}</strong>
        </div>
    </section>
  </v-content>
</template>

<script>
export default {
  name: "Privacy",
  data: () => ({
    referral: null
  })
};
</script>

<style lang="stylus">
@import "~@/main.styl"
</style>
