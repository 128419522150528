<template>
  <v-content class="component white black--text">
    <section class="text-xs-center pa-3" id="about">
      <div class="max-700">
        <div class="header primary--text my-3 text-xs-center">
          <h2>{{ $t("tWho") }}</h2>
        </div>
        <!-- Mission -->
        <h2 class="header text-xs-center mb-3">{{ $t("tMission") }}</h2>
        <p
          class="text-xs-justify"
        >{{ $t("tGoal") }}</p>
        <!-- Company -->
        <h2 class="header text-xs-center my-3">{{ $t("tCompany") }}</h2>
        <p
          class="text-xs-justify"
        >{{ $t("tIndustryLeader") }}</p>
        <!-- Support -->
        <h2 class="header text-xs-center my-3">{{ $t("tMarketingSupport") }}</h2>
        <v-layout row wrap>
          <v-flex xs12 sm6>
            <p class="text-xs-left">
              {{ $t("tEmailUs") }}:
              <a href="mailto:support@g2gotravelclub.com">support@g2gotravelclub.com</a>
              <!-- <br />Telephone Us:
              <a href="tel:+13072018088">+1(307) 201-8088</a> -->
              <br />
            </p>
          </v-flex>
          <v-flex xs12 sm6>
            <p class="text-xs-left">
              109 East 17th Street, Suite 80
              <br />Cheyenne, Wyoming, USA
            </p>
          </v-flex>
        </v-layout>
      </div>
    </section>
  </v-content>
</template>

<script>
export default {
  name: "About",
  data: () => ({
    active: null,
    i18n: {
      "en": {
      "title": "WHO'S G2 GO TRAVEL CLUB?",
      "missionTitle": "Our Mission",
      "missionText": "Our goal is to create the largest and most respected travel club in the world. G2 GO Travel Club's superior field and corporate leadership create the ultimate opportunity for our members. Our people and members are the foundation of our success. Enriching lives and improving lifestyles will always be our lasting commitment.",
      "companyTitle": "Our Company",
      "companyText": "G2 GO Travel Club, founded in the USA, is a pioneer and leader in the industry of travel since 2001. Led by executives with decades of experience in the industry, our team's expertise stretches back to 1980. G2 GO Travel Club has active representatives and members in more than sixty countries."
      }
    }
  })
};
</script>

<style scoped lang="stylus">
@import '~@/main.styl';

.full-height .flex {
  display: flex;
}

.full-height .flex > .card {
  flex: 1 1 auto;
}
</style>