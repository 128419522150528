import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from './views/Home.vue'
// import JoinView from './views/JoinPage.vue'
import Privacy from './views/Privacy.vue'
import Terms from './views/Terms.vue'
import LoginView from './views/LoginPage.vue'

Vue.use(VueRouter)

export default new VueRouter({
  mode: 'history',
  // eslint-disable-next-line
  scrollBehavior: function(to, from, savedPosition) {
    if (to.hash) {
      // offset for menu bar
      return {
        selector: to.hash,
        offset: { x: 0, y: -56 }
      }
    } else {
      return { x: 0, y: 0 }
    }
  },
  pushWithAnchor: function (routeName, toHash) {
    const fromHash = VueRouter.history.current.hash
    fromHash !== toHash || !fromHash
    ? VueRouter.push({ name: routeName, hash: toHash })
    : VueRouter.push({ name: routeName, hash: fromHash }, undefined, () => { window.location.href = toHash })
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/login',
      name: 'login',
      component: LoginView
    },
    {
      path: '/privacy',
      name: 'privacy',
      component: Privacy
    },
    {
      path: '/terms',
      name: 'terms',
      component: Terms
    },
    {
      path: '*',
      name: 'default',
      component: Home
    }
  ]
})
