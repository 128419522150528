import Vue from 'vue'
import './plugins/vuetify'
import App from './App.vue'
import router from './router'
import vueSmoothScroll from 'vue2-smooth-scroll'
import i18n from './i18n'

// router.beforeEach((to,from,next) => {
//   var subdir = window.location.host.split('.')[0]
//   var domain = 'g2gotravelclub.net'
// })

Vue.use(vueSmoothScroll)
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
  i18n,
  mounted: () => document.dispatchEvent(new Event("x-app-rendered"))
}).$mount('#app')
