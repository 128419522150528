<template>
  <v-content>
    <!-- above the fold -->
    <section id="top">
      <v-container fill-height grid-list-md text-xs-center>
        <v-layout row wrap align-center class="component white--text">
          <v-flex xs12>
            <!--  v-if="$i18n.locale == 'en'" -->
            <div class="top-title">
              G2<span class="font-weight-black">GO</span>
            </div>
            <div class="top-subtitle" v-if="$i18n.locale == 'en'">
              <span class="font-weight-black">Travel</span>Club
            </div>
            <div class="top-subtitle" v-else>
              <span class="font-weight-black">{{ $t("tTravelClub") }}</span>
            </div>
            <img src="../assets/g2g_white.png" style="max-width: 50%;">
            <div class="cursive white--text black-shadow mb-3">{{ $t("tTagline") }}</div>
            <a href="#benefits" v-smooth-scroll="{offset:-56}">
              <v-icon class="white--text pb-5 black-shadow-hover" style="font-size: 4em;" large>arrow_drop_down_circle</v-icon>
            </a>
          </v-flex>
        </v-layout>
      </v-container>
    </section>
    <hr>
    <Benefits></Benefits>
    <Membership></Membership>
    <hr>
    <Opportunity></Opportunity>
    <hr>
    <About></About>
  </v-content>
</template>

<script>
import Benefits from './../components/Benefits.vue'
import Membership from './../components/Membership.vue'
import Opportunity from './../components/Opportunity.vue'
import About from './../components/About.vue'

export default {
  name: "Home",
  components: {
    Benefits,
    Membership,
    Opportunity,
    About
  },
  data: () => ({
    tagline: "We Are Going Places!",
    dialog: null
  })
};
</script>

<style lang="stylus">
@import '~@/main.styl';
</style>
